import { ReqPage } from '@/model/common/reqPage'
//账户信息类
export class CompetitorAccount {
  id: string | undefined //主键,
  competitorId: string | undefined //同行id【必须】,
  username: string | undefined //同行账号,
  password: string | undefined //同行密码,
  alias: string | undefined //别名,
  isEnable: number | undefined //是否启用;1:启用 0:禁用 2:封禁,
  isDeleted: number | undefined //是否删除;0:未删除 1:已删除,
  createdBy: string | undefined //创建人,
  createdTime: string | undefined //创建时间,
  upstringdBy: string | undefined //更新人,
  upstringdTime: string | undefined //更新时间,
  description: string | undefined //描述,
  createName: string | undefined //创建人姓名
  constructor() {
    this.id = undefined
    this.competitorId = undefined
    this.username = undefined
    this.password = undefined
    this.alias = undefined
    this.isEnable = undefined
    this.isDeleted = undefined
    this.createdBy = undefined
    this.createdTime = undefined
    this.upstringdBy = undefined
    this.upstringdTime = undefined
    this.description = undefined
    this.createName = undefined
  }
}
//账户分页查询请求
export class ReqCompetitorAccount extends ReqPage {
  data: CompetitorAccount
  constructor() {
    super()
    this.data = new CompetitorAccount()
  }
}
//账户分页查询返回接口
export interface ResCompetitorAccount extends ReqPage {
  data: CompetitorAccount[] | undefined
}
