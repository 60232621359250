
import { defineComponent, toRefs, ref, computed, reactive, Ref } from 'vue'
import listLayout from '@/components/listLayout/listLayout.vue'
import FormItem from '@/components/formItem/index.vue'
import editButton from '@/components/editButton/index.vue'
import { message } from 'ant-design-vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import yTable from '@/components/yTable/index.vue'
import { deleteModal } from '@/utils/deleteModal'
import Data from '@/model/common/Data'
import {
  CompetitorAccount,
  ReqCompetitorAccount,
} from '@/model/competitiveAnalysis/peerData/competitorAccount'
import accompanyApi from '@/axios/api/accompany'
import modalForm from '@/components/modalForm/index.vue'
import { FormItemListType } from '@/types/comm'
export default defineComponent({
  components: {
    listLayout,
    FormItem,
    editButton,
    yTable,
    modalForm,
  },
  setup() {
    //获取新增编辑弹窗组件
    const addUpdateInformation = ref()
    //获取路由
    let route = useRoute()
    //获取store
    const store = useStore()
    const buttonObj = store.getters.getButtonObj
    const propsTitle = ref('')
    propsTitle.value = route.query.name as string
    const pageData = reactive({
      selectFrom: new ReqCompetitorAccount(),
      dataSource: [] as CompetitorAccount[],
      columns: [
        {
          title: '账号',
          dataIndex: 'username',
          align: 'center',
          key: 'username',
          width: 130,
          resizable: true,
        },
        {
          title: '密码',
          dataIndex: 'password',
          align: 'center',
          key: 'password',
          width: 100,
          resizable: true,
        },
        {
          title: '别名',
          dataIndex: 'alias',
          align: 'center',
          key: 'alias',
          width: 130,
          resizable: true,
        },
        {
          title: '启用状态',
          dataIndex: 'isEnable',
          align: 'center',
          key: 'isEnable',
          width: 100,
          resizable: true,
        },
        {
          title: '描述',
          dataIndex: 'description',
          align: 'center',
          key: 'description',
          width: 130,
          resizable: true,
        },
        {
          title: '创建人',
          dataIndex: 'createName',
          align: 'center',
          key: 'createName',
          width: 130,
          resizable: true,
        },
        {
          title: '创建时间',
          dataIndex: 'createdTime',
          align: 'center',
          key: 'createdTime',
          width: 130,
          resizable: true,
        },
        // {
        //   title: '操作',
        //   align: 'center',
        //   slots: {
        //     customRender: 'edit',
        //   },
        //   width: 130,
        // },
      ],
      selectedRowKeys: [] as string[],
      loading: false,
      formItemList: [
        {
          type: 'subInput',
          prop: 'username',
          placeholder: '请输入用户名',
          label: '用户名',
        },
        {
          type: 'subInput',
          prop: 'alias',
          placeholder: '请输入别名',
          label: '别名',
        },
        {
          type: 'subSelect',
          prop: 'isFollow',
          placeholder: '请选择是否启用',
          label: '是否启用:',
          optionList: [
            {
              value: 1,
              label: '是',
            },
            {
              value: 0,
              label: '否',
            },
            {
              value: 2,
              label: '封禁',
            },
          ],
        },
      ] as FormItemListType<CompetitorAccount>,
      stopVisible: false,
      primaryIndustry: [],
      options: [] as Data[],
    })
    //新增按钮
    const addAccount = () => {
      modalData.form = new CompetitorAccount()
      modalData.form.isEnable = 1
      modalData.title = '新增账号'
      modalData.okText = '保存'
      modalData.visible = true
    }
    //编辑按钮
    const updateAccount = () => {
      if (pageData.selectedRowKeys.length !== 1) {
        message.warning('请选择一项数据！')
        return
      }
      Object.assign(
        modalData.form,
        pageData.dataSource.find(
          (item) => item.id === pageData.selectedRowKeys[0]
        ) as CompetitorAccount
      )
      modalData.title = '修改账号'
      modalData.okText = '修改'
      modalData.visible = true
    }
    //传入table的页面配置
    const currentConfigure = computed(() => {
      let page = {
        pageSize: pageData.selectFrom.pageSize,
        currentPage: pageData.selectFrom.currentPage,
        total: pageData.selectFrom.dataCount,
        onChange(page: number, pageSize: number) {
          pageData.selectFrom.pageSize = pageSize
          pageData.selectFrom.currentPage = page
          initeList()
        },
        onShowSizeChange(current: number, size: number) {
          pageData.selectFrom.pageSize = size
          pageData.selectFrom.currentPage = current
          initeList()
        },
      }
      return page
    })
    //获取表格信息
    const initeList = () => {
      pageData.loading = true
      pageData.selectedRowKeys.length = 0
      pageData.selectFrom.data.competitorId = route.params.id as string
      accompanyApi
        .CompetitorAccountList(pageData.selectFrom)
        .then((res) => {
          pageData.dataSource = []
          if (res.data) {
            Object.assign(pageData.dataSource, res.data)
          }
          pageData.selectFrom.currentPage = res.currentPage as number
          pageData.selectFrom.pageSize = res.pageSize
          pageData.selectFrom.dataCount = res.dataCount
          pageData.loading = false
        })
        .catch((err) => {
          pageData.loading = false
          message.error(err)
        })
    }
    //重置数据
    const selectList = () => {
      pageData.selectFrom.currentPage = 1
      pageData.selectFrom.pageSize = 10
      initeList()
    }
    //查询数据
    selectList()
    //
    const reset = () => {
      pageData.selectFrom.data = new CompetitorAccount()
      selectList()
    }
    //删除按钮
    const delData = () => {
      if (pageData.selectedRowKeys.length === 0) {
        message.warning('至少选中一条')
        return
      }
      let data = pageData.selectedRowKeys.map((item) => {
        return {
          id: item,
          competitorId: route.params.id,
        }
      })
      const ok = () => {
        accompanyApi
          .CompetitorAccountDelete(data)
          .then(() => {
            message.success('删除成功')
            initeList()
          })
          .catch((err) => {
            message.error(err)
          })
      }
      const onCancel = () => {
        console.log('Cancel')
      }
      deleteModal(ok, onCancel)
    }
    const modalData = reactive({
      title: '新增账号',
      form: new CompetitorAccount(),
      formItemList: [
        {
          type: 'subInput',
          prop: 'username',
          placeholder: '请输入账号',
          label: '账号',
        },
        {
          type: 'subInput',
          prop: 'password',
          placeholder: '请输入密码',
          label: '密码',
          inputType: 'password',
        },
        {
          type: 'subInput',
          prop: 'alias',
          placeholder: '请输入账号别名',
          label: '账号别名',
        },
        {
          type: 'switch',
          prop: 'isEnable',
          placeholder: '请输入开启状态',
          label: '开启状态',
        },
        {
          type: 'textarea',
          prop: 'description',
          placeholder: '请输入描述',
          label: '描述',
        },
      ],
      rules: {
        username: [
          { required: true, message: '请输入账号', trigger: 'blur' },
          { min: 6, max: 16, message: '账号长度为6-16位', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 16, message: '密码长度为6-16位', trigger: 'blur' },
        ],
        alias: [{ required: true, message: '请输入账号别名', trigger: 'blur' }],
        isEnable: [{ required: true }],
      },
      visible: false,
      labelCol: { span: 4 },
      refName: 'formRef',
      cancel: (formRef?: Ref) => {
        formRef?.value.resetFields()
      },
      okText: '保存',
      ok: (formRef?: Ref) => {
        modalData.form.competitorId = route.params.id as string
        formRef?.value.validate().then(() => {
          accompanyApi[
            modalData.title === '新增账号' ? 'CompetitorAccountAdd' : 'CompetitorAccountUpdate'
          ](modalData.form)
            .then(() => {
              message.success(modalData.title === '新增账号' ? '新增成功' : '修改成功')
              initeList()
              modalData.visible = false
            })
            .catch((err) => {
              message.error(err)
            })
        })
      },
    })
    const onUpdateForm = (val: undefined, prop: keyof CompetitorAccount) => {
      modalData.form[prop] = val
    }
    return {
      buttonObj,
      ...toRefs(pageData),
      addAccount,
      updateAccount,
      currentConfigure,
      reset,
      delData,
      initeList,
      labelCol: { style: { width: '100px' } },
      selectList,
      addUpdateInformation,
      propsTitle,
      modalData,
      onUpdateForm,
    }
  },
})
